body {
  margin: 0;
  padding: 0;
  font-family: 'Rubik', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root { height: 100%}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.MuiPickersCalendarHeader-dayLabel {
  color: rgb(114, 117, 120) !important;
}
